import request from "../../../common/utils/request";

// 获取成果库信息
export function getAchievementsById(params) {
  return request({
    url: "/itf/common/cjrh/achievements/getAchievementsById.json",
    method: "get",
    params: params,
  });
}

// 成果库搜索
export function getAchievementss(params) {
  return request({
    url: "/itf/common/cjrh/achievements/getAchievementss.json",
    method: "get",
    params:params,
  });
}
